import React, {useEffect, useState} from 'react'
import {graphql, Link} from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../components/layout'
import Avatar from '../images/avatar-placeholder.png'
import PersonalVerdictItem from '../components/verdict-item'
import SEO from '../components/seo'
import axios from "axios";
import ballot from "../images/ic-ballot.png";
import Org from "../images/org-placeholder.png";
import {ChevronRight} from "@styled-icons/octicons";
import queryString from "query-string";
import {format} from "date-fns";

const config = require('../config/default');


function Company() {
    const search = typeof window !== `undefined` ? location.search : {q: '/none/'}
    let params = queryString.parse(search)

    let companyId = params.id
    // console.log("companyId", companyId)

    const [state, setState] = useState({
        company: {
            "name": "",
            "image": "",
            "partiesOrganizationids": [],
            "partiesDonorids": [],
            "partiesDefendantsPersonids": []
        },
        donateToParties: [],
        donateTransactions: [],
        companyId: companyId,
        loading: false,
        is_search: true
    });

    let FIND_COMPANY = function (search) {
        // console.log("FIND_COMPANY")


        if (search === null) return []

        let query = `
query($uid: String) {
  allCompanies(id: $uid) {
    edges {
      node {
        id
        name
        image
        partiesOrganizationids {
          memberid {
            id
            name
          }
          role
          
        }
        partiesDonorids {
          donatetoid {
            name
            image
            partytype
          }
          donatedat
          value
        }
      partiesDefendantsPersonids {
          id
          name
          position
          judgement
          verdictnote
          verdictid {
            verdictdate
            redcaseid
            blackcaseid
            accusedfor
            note
          }
        }
      }
    }
  }
}
          `.replace(/\n/g, ' ');

        axios.post(config.GRAPHQL_API_URL, {
            query: query,
            variables: {
                "uid": state.companyId
            }
        })
            .then(function (response) {
                if (response.data.data.allCompanies.edges.length > 0) {
                    let company = response.data.data.allCompanies.edges[0].node;
                    // console.log(company)

                    const parties = []
                    const transactions = []
                    company.partiesDonorids
                        .forEach(it => {
                            transactions.push(it)
                            let p = parties.find(p => p.id === it.id)
                            if (!p) {
                                p = it
                                p.totalReceiveAmount = 0.0
                                parties.push(p)
                            }
                            p.totalReceiveAmount = (p.totalReceiveAmount || 0) + (it.value || 0)
                        })


                    setState({
                        "is_search": false,
                        "loading": false,
                        "donateToParties": parties,
                        "donateTransactions": transactions,
                        "companyId": state.companyId,
                        "company": company
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        setState({
            "is_search": false,
            "loading": false,
            "donateToParties": [],
            "donateTransactions": [],
            "companyId": state.companyId,
            company: {
                "name": "",
                "image": "",
                "partiesOrganizationids": [],
                "partiesDonorids": [],
                "partiesDefendantsPersonids": []
            },
        });


    }

    if (state.is_search) FIND_COMPANY(companyId);
    const company = state.company
    const donateToParties = state.donateToParties
    const donateTransactions = state.donateTransactions.sort(function (a, b) {
        return new Date(a.donatedat) >= new Date(b.donatedat)? 1: -1
    })
    const getRoleName = ({role}) => {
        return {
            leader: 'หัวหน้าพรรค',
            mp: 'ส.ส.',
        }[role]
    }

    let avatarImage = Avatar
    if (company.image && company.image !== "") {
        avatarImage = company.image
    }


    const [isShowTransaction, setIsShowTransaction] = useState(false)
    let setExpandTransaction = function () {
        setIsShowTransaction(!isShowTransaction);
    }

    let convertDateToThai = function (date) {
        var monthsTh =  [ "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม", ];

        return format(date, 'dd') + " " +
            monthsTh[parseInt(format(date, 'MM')) - 1] + " " +
            (parseInt(format(date, 'yyyy')) + 543)
    }



    const headerBlock = () => (<div className="flex flex-col sm:flex-row max-w-6xl mx-auto px-4 md:px-8 py-2 md:py-4">
        <div className="relative text-center sm:w-24">
            <img src={Avatar} alt="avatar image"
                 className="inline-block rounded-full shadow-xl w-20 sm:w-24 sm:absolute sm:top-0 sm:left-0"/>
        </div>
        <h1 className="ml-0 sm:ml-8 text-white text-center sm:text-left text-xl sm:text-2xl md:text-4xl">{company.name}</h1>
    </div>)

    return (
        <Layout >
            <SEO
                keywords={[`organization`]}
                title={company.name}
            />
            <div className="grid grid-cols-1 md:grid-cols-4" style={{'min-height': '80vh'}} >
                <div className="bg-sidebar" >
                    <div className="">
                        <div className="flex justify-center -mt-5">
                            <img src={Avatar} alt="avatar image"
                                 className="rounded-full shadow-xl w-16 md:w-20" />
                        </div>
                        <div className="ml-auto">
                            <h1 className="px-5 mt-5 text-black text-center text-xl sm:text-xl md:text-2xl">{company.name}</h1>

                        </div>
                    </div>
                    <div className="mt-10 text-center">
                        <a className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded " href={"https://actai.co/Project?search=\"" + company.name + "\""} target="_blank">ค้นหาต่อใน ACT Ai</a>
                    </div>
                </div>
                <div className="col-span-3 bg-main">
                    <div className="mx-5 my-5">
                        <div className="relative bg-white  rounded-xl py-0 px-0 shadow-xl mt-3 overflow-hidden">
                            <div className="flex">
                                <div className="hidden md:flex text-center" style={{'background-color': '#FF0000', 'width':'120px'}}>
                                    <img src={ballot} alt="avatar image"
                                         className="mx-auto my-auto w-10" />
                                </div>
                                <div className="px-2 py-5 w-full">
                                    <h2 className="text-xl md:text-2xl lg:text-3xl font-bold">ความเกี่ยวข้องกับพรรคการเมือง</h2>
                                    {donateToParties.length ?
                                        <ul className="list-none">
                                            {donateToParties.map(it =>
                                                <li className="mt-3 mb-1 p-3" key={it.id}>
                                                    <div className="flex">
                                                        <div>
                                                            <div className="text-sm">บริจาคให้พรรค</div>
                                                            <div className="text-2xl font-bold">{it.donatetoid.name}</div>
                                                        </div>
                                                        <div className="ml-auto text-right">
                                                            <div className="text-sm">ยอดเงิน (บาท)</div>
                                                            <div
                                                                className="text-xl font-bold" style={{'color': '#FF0000'}}>{it.totalReceiveAmount.toLocaleString('en-US', {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            })}</div>
                                                            <button onClick={() => setExpandTransaction()}>รายละเอียด</button>
                                                        </div>
                                                    </div>
                                                </li>
                                            )}

                                        </ul>
                                        :
                                        <div className="mt-3 bg-orange-200 p-3 text-center">ไม่มีข้อมูล</div>
                                    }

                                    { isShowTransaction?
                                        <div>
                                            {donateTransactions && donateTransactions.length ?
                                                <table className="table-auto w-full mt-2">
                                                    <tbody>
                                                    {donateTransactions.map(it => <tr>
                                                            <td className="text-left px-1 py-1"><span
                                                                className="inline-block w-2 h-2 mr-2 bg-red-600 rounded-full"></span>
                                                            </td>
                                                            <td className="text-left px-1 py-1">{convertDateToThai(new Date(it.donatedat))}</td>
                                                            <td className="text-left px-1 py-1">{it.donatetoid.name}</td>
                                                            <td className="text-left px-1 py-1">{it.value.toLocaleString('en-US', {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            })}</td>
                                                        </tr>
                                                    )}
                                                    </tbody>
                                                </table>
                                                : <div className="mt-3 bg-gray-100 p-3 text-center">ไม่มีข้อมูล</div>}

                                        </div>: <div></div>
                                    }


                                </div>

                            </div>


                        </div>

                        <div className="relative bg-white py-0 px-0 rounded-xl shadow-xl mt-5">
                            <div className="grid grid-flow-col gap-0 px-2 py-5">
                                {/*<div className="grid-cols-1"></div>*/}
                                <div className="grid-cols-11  px-2 py-2">
                                    <h2 className="text-xl md:text-2xl lg:text-3xl font-bold">ข้อมูลคดีความ</h2>
                                    {company.partiesDefendantsPersonids.length ? <ul className="list-none">
                                        {company.partiesDefendantsPersonids.map(it =>
                                            <li className="mt-3 mb-1 bg-orange-200 p-3" key={it.id}>
                                                {PersonalVerdictItem({item: it})}
                                            </li>)}
                                    </ul> : <div className="mt-3 bg-gray-100 p-3 text-center">ไม่มีข้อมูล</div>}
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </Layout>
    )
}


export default Company

